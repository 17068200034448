import React from "react";
import { classNames } from "../classnames";
import { IButtonProps } from "./IButtonProps";
import styles from './styles.module.scss';


export const Button: React.FC<IButtonProps> = React.forwardRef(({ type = 'button', indicateProgress, disabled, onClick, className, href, target, variant, children }, ref) => {
  const C = !!href ? 'a' : 'button';

  return (
    <C
      className={classNames({ button: variant !== 'arrow', 'button-arrow': variant === 'arrow', [styles.buttonArrow]: variant === 'arrow' }, "w-button", styles.container, className)}
      onClick={onClick}
      disabled={disabled}
      type={type}
      ref={ref as any}
      {...{
        target,
        href
      }}
    >
      {indicateProgress && <div className={styles.spinnerContainer}><div className={styles.spinner}></div></div>}
      {children}
    </C>
  );
})