import { ReactNode, useState } from "react";

export interface IDialogStateProps {
  open: boolean;
  onClose: () => void;
}

export const useDialogState = (initialState: boolean = false) => {
  const [open, setOpen] = useState(initialState);

  return {
    open,
    onClose: () => setOpen(false),
    onOpen: () => setOpen(true)
  }
}