import { useFormikContext } from "formik";
import { useMemo, useState } from "react";
import { Select, SelectOption } from "../Select";
import { TextField } from "../TextField";
import { FormFieldProps } from "./FormFieldProps";



export const FormField = <Form extends object>(): React.FC<FormFieldProps & { fieldName: keyof Form }> => ({ fieldName, type = 'text', label }) => {
  const form = useFormikContext<{ [key: string]: string }>();
  const [dirty, setDirty] = useState(false);
  const touched = form.touched[fieldName] || (type == 'select' && dirty);
  const submitted = form.submitCount > 0;
  const hasError = !!form.errors[fieldName];
  const showHelperText = hasError && (submitted || (dirty && touched));

  const sharedProps = {
    id: fieldName,
    label: label,
    value: form.values[fieldName],
    onChange: (e: React.ChangeEvent<any>) => { setDirty(true); form.handleChange(e); },
    onBlur: form.handleBlur,
    indicateError: submitted && hasError,
    helperText: showHelperText ? form.errors[fieldName] : undefined
  };

  switch(type) {
    case 'text':
    case 'password': {
      return (
        <TextField
          {...sharedProps}
          password={type == 'password'}
        />
      );
    }
    case 'select': {
      return (
        <Select
          {...sharedProps}
        >
          {form.status?.selectOptions?.[fieldName]?.map(({ label, value }: any) => {
            return (
              <SelectOption
                key={value}
                value={value}
              >
                {label}
              </SelectOption>
            )
          })}
        </Select>
      )
    }
  }
}