import { ISelectOptionProps } from "./ISelectOptionProps";

export const SelectOption: React.FC<ISelectOptionProps> = ({ value, children }) => {
  return (
    <option
      value={value}
    >
      {children}
    </option>
  );
}