export const v2 = (() => {
  const v2 = {
    students: '/students',
    klassBySecretCode: (secret_code: string) => `/klasses?secret_code=${secret_code}`,
    studentsByClassCode: (klass_code: string) => `/students?klass_code=${klass_code}`,
    studentsByKlassSecretCode: (klass_secret_code: string) => `/students?klass_secret_code=${klass_secret_code}&include_klass=true`,
    studentByCode: (student_code: string) => `/students?student_code=${student_code}&version=15.9.9`
  }
  
  Object.keys(v2).forEach((key: keyof typeof v2) => {
    if (typeof v2[key] === 'string') {
      v2[key] = process.env.PUBLIC_API_URL + '/v2' + v2[key] as any
    } else {
      const orig = v2[key] as any;
      v2[key] = ((...args: any[]) => process.env.PUBLIC_API_URL + '/v2' + (orig)(...args)) as any
    }
  });

  return v2;
})();