import { IPageDefinition } from "./IPageDefinition"
import { createRoot } from 'react-dom/client';

export const runEmbeddedPage = (pageDefinition: IPageDefinition<React.ComponentType>) => {  
  var link = document.createElement('link');
  link.href = `${process.env.PUBLIC_PATH}/ui-webflow-pages/${pageDefinition.exportTitle}.css`;
  link.rel = "stylesheet";
  link.type = "text/css";
  document.head.appendChild(link);

  document.querySelectorAll(`.${pageDefinition.selectorClass}`)
    .forEach(domContainer => {
      const root = createRoot(domContainer);
      const Component = pageDefinition.component;
      root.render(<Component />);
    });
}