import classNames from 'classnames';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button } from 'ui-components/src/Button';
import { Dialog, useDialogState } from 'ui-components/src/Dialog';
import { FormField } from 'ui-components/src/FormField';
import { yup } from 'ui-components/src/yup';
import { v2 } from '../../../endpoints/src/v2';
import styles from './styles.module.scss';

export default () => {
  const dialogState = useDialogState();

  useEffect(() => {
    const els = document
      .querySelectorAll('[data-trigger-parent-code-dialog]');

    const listener = () => {
      dialogState.onOpen();
    }

    els
      .forEach(element => {
        element.addEventListener('click', listener);
      });

    return () => {
      els.forEach(element => {
        element.removeEventListener('click', listener);
      })
    }
  }, []);

  const [notFoundError, setNotFoundError] = useState(false);
  const [genericError, setGenericError] = useState(false);
  const [alreadyLinkedError, setAlreadyLinkedError] = useState(false);

  const form = useFormik<IParentCodeForm>({
    validateOnChange: false,
    initialValues: {
      parent_code: ''
    },
    onSubmit: async ({ parent_code }) => {
      setNotFoundError(false);
      setAlreadyLinkedError(false);
      setGenericError(false)
      try {
        const res = await fetch(`${v2.students}?student_code=${parent_code}`, {
          headers: {
            'kodable-api-token': process.env.PUBLIC_API_TOKEN
          }
        });

        if (res.status === 404) {
          return setNotFoundError(true);
        }

        if (res.status === 200) {
          const body = await res.json();

          if (body.klass_id && body.parent_id === null) {
            return window.location.href = `${process.env.PUBLIC_WEBSITE_URL}/register?s=${parent_code}`
          }

          return setAlreadyLinkedError(true);
        }

        setGenericError(true)
      } catch (e) {
        setGenericError(true)
      }
    },
    validationSchema: yup.object().shape({
      parent_code: yup.string().required('Enter a parent code')
    })
  })

  return <>
    <Dialog
      {...dialogState}
    >
      <FormikProvider value={form}>
        <Form>
          <div
            className={styles.content}
          >
            <h1 className={styles.title}>Enter a Parent Code</h1>
            <p className={classNames(styles.paragraph, 'paragraph')}>Redeem your Parent Code!</p>
            <div className={styles.input}>
              <ParentCodeFormField
                fieldName="parent_code"
              ></ParentCodeFormField>
            </div>
            {genericError && <div className={styles.error}>
              We couldn't reach our servers. Try again later.
            </div>}
            {notFoundError && <div className={styles.error}>
              We couldn't find that parent code.
            </div>}
            {alreadyLinkedError && <div className={styles.error}>
              This parent code has already been linked to an account.
            </div>}
            <div className={styles.actions}>
              <a target="_blank" href={`${process.env.PUBLIC_WEBSITE_URL}/parents`}>Learn More About Pro Memberships</a>
              <Button
                indicateProgress={form.isSubmitting}
                type="submit"
              >Continue</Button>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </Dialog>
  </>;
}

interface IParentCodeForm {
  parent_code: string;
}

const ParentCodeFormField = FormField<IParentCodeForm>();
