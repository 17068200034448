import MuiDialog, { DialogProps } from "@mui/material/Dialog";
import { IDialogStateProps } from "./useDialogState";
import styles from './styles.module.scss';
import trapezoidSvg from './trapezoid.svg';
import DialogContent from '@mui/material/DialogContent';
import { ReactNode } from "react";
import img_exit from './exit.png';

interface IDialogProps {
  children: ReactNode;
  maxWidth?: DialogProps['maxWidth'];
  hideExitButton?: boolean;
}

export const Dialog: React.FC<IDialogStateProps & IDialogProps> = (props) => {
  return <MuiDialog
    fullWidth
    classes={{
      // container: styles.muiContainer,
      paper: styles.muiPaper
    }}
    {...props}
  >
    <DialogContent
    
    >
      {props.children}
    </DialogContent>
    {!props.hideExitButton && <img
      className={styles.exitButton}
      src={img_exit}
      height={32}
      onClick={props.onClose}
    />}
  </MuiDialog>
}