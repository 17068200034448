import { useMemo } from "react";
import { classNames } from "../classnames";
import { TextFieldProps } from "./TextFieldProps"
import styles from './styles.module.scss';

export const TextField: React.FC<TextFieldProps> = ({ id, value, disabled, password, label, helperText, indicateError, onChange, onBlur  }) => {
  const helperTextNode = useMemo(() => {
    if (!!helperText && helperText.length > 0) {
      return (
        <div
          className={classNames("text-gray")}
        >
          {helperText}
        </div>
      )
    }
  }, [helperText]);

  const labelNode = useMemo(() => {
    if (!!label && label.length > 0) {
      return <label
        htmlFor={id}
      >
        {label}
      </label>
    }
  }, [id, label]);

  return (
    <div
      className={classNames(styles.container)}
    >
      {labelNode}
      <input
        className="w-input"
        id={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
        onBlur={onBlur}
        type={password ? 'password' : 'text'}
      />
      {helperTextNode}
    </div>
  );
}